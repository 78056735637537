import React, { Fragment } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

function Contact() {
  return (
    <Fragment>
      <div className="offwrap" />
      <div className="main-content">
        <Header isHome={false} />

        <div className="rs-breadcrumbs img8">
          <div className="container">
            <div className="breadcrumbs-inner contact-inner-style">
              <div className="row y-middle">
                <div className="col-lg-6 md-mb-50">
                  <div className="breadcrumbs-wrap">
                    <h1 className="page-title">
                      We are your social media architects
                    </h1>
                    <p className="description">
                      Embark on a digital adventure with Snarvil,
                      your trusted Instagram management agency based in Bali.
                      Our skilled team combines the beauty of this island with
                      low-cost solutions, ensuring your brand shines in the
                      online spotlight. Experience top-tier social media
                      management without compromise – welcome to Bali-inspired
                      excellence.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="team-img">
                    <img
                      src="/assets/images/breadcrumbs/images/contact.png"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
              <div className="shape-animation">
                <div className="team-animate">
                  <img
                    className="scale"
                    src="/assets/images/breadcrumbs/shape/img5.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rs-contact contact-style2 contact-modfiy5 pt-130 md-pt-80">
          <div className="container mb-50">
            <div className="row y-middle">
              <div className="col-lg-6 pr-135 md-pr-15 md-mb-50">
                <div className="sec-title">
                  <h2 className="title title2 pb-35">
                    Let’s talk
                    <br />
                    about your project
                  </h2>
                  <p className="desc pb-60">
                    Ready to transform your social media presence? Reach out to
                    us, and let's turn your vision into a compelling digital
                    reality. Together, we'll craft a tailored strategy that
                    propels your brand to new heights.
                  </p>
                </div>
                <div className="address-boxs mb-30">
                  <div className="address-icon">
                    <i className="fa fa-envelope-o" />
                  </div>
                  <div className="address-text">
                    <div className="text">
                      <span className="label">Email:</span>
                      <span className="des">info@snarvil.com</span>
                    </div>
                  </div>
                </div>
                <div className="address-boxs">
                  <div className="address-icon">
                    <i className="fa fa-phone" />
                  </div>
                  <div className="address-text">
                    <div className="text">
                      <span className="label">Phone:</span>
                      <span className="des">(+62)81547655988</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-wrap">
                  <div id="form-messages" />
                  <form id="contact-form" method="post" action="mailer.php">
                    <fieldset>
                      <div className="row">
                        <div className="col-lg-12 mb-30">
                          <label>Name</label>
                          <input
                            className="from-control"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Enter Your name"
                            required=""
                          />
                        </div>
                        <div className="col-lg-12 mb-30">
                          <label>Email</label>
                          <input
                            className="from-control"
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Enter your email address"
                            required=""
                          />
                        </div>
                        <div className="col-lg-12 mb-30">
                          <label> How can we help you?</label>
                          <textarea
                            className="from-control"
                            id="message"
                            name="message"
                            placeholder="Type your messags here"
                            required=""
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="btn-part">
                        <div className="form-group mb-0">
                          <p className="submit-btn submit-stle2">
                            <input type="submit" defaultValue="Submit Now" />
                          </p>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </Fragment>
  );
}

export default Contact;
