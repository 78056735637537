import React, { Fragment } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Portfolios from "../portfolio.json";
import { useParams } from "react-router-dom";

function PortfolioSingle() {
  const { slug } = useParams();

  const portfolio = Portfolios.filter((portfolio) => portfolio.slug == slug)[0];

  return (
    <Fragment>
      <div className="offwrap" />
      <div className="main-content">
        <Header isHome={false} />

        <div className="rs-project-details pt-150 pb-150 md-pt-80 md-pb-80">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6 md-mb-50">
                <div className="project-img">
                  <img
                    src={"/assets/images/portfolio/" + portfolio.slug + "/" + portfolio.images[0]}
                    alt={portfolio.title}
                  />
                </div>
              </div>
              <div className="col-lg-6 pl-50 md-pl-15">
                <div className="prelements-heading">
                  <div className="sec-title mb-35">
                    <span className="sub-text primary-color">{portfolio.category}</span>
                    <h2 className="title pb-22">{portfolio.title}</h2>
                    <p className="desc pb-22">
                      {portfolio.overview}
                    </p>
                    <a href={portfolio.link} className="mt-20">
                      <i target="_blank" className="fa fa-instagram fa-3x fa-xl" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-widget-img pt-40">
              <div className="row">
                <div className="col-lg-6 mb-40">
                  <div className="project-img">
                    <img
                      src={"/assets/images/portfolio/" + portfolio.slug + "/" + portfolio.images[1]}
                      alt={portfolio.title}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-40">
                  <div className="project-img">
                    <img
                      src={"/assets/images/portfolio/" + portfolio.slug + "/" + portfolio.images[2]}
                      alt={portfolio.title}
                    />
                  </div>
                </div>
                <div className="col-lg-6 md-mb-40">
                  <div className="project-img">
                    <img
                      src={"/assets/images/portfolio/" + portfolio.slug + "/" + portfolio.images[3]}
                      alt={portfolio.title}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="project-img">
                    <img
                      src={"/assets/images/portfolio/" + portfolio.slug + "/" + portfolio.images[4]}
                      alt={portfolio.title}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </Fragment>
  );
}

export default PortfolioSingle;
