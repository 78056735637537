import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Header(props) {
  return (
    <Fragment>
      <div className="full-width-header">
        <header
          id="rs-header"
          className={"rs-header header-transparent" + ( props.isHome ? ' header-style1' : '' )}
        >
          <div className="menu-area menu-sticky">
            <div className="container">
              <div className="row-table">
                <div className="col-cell header-logo">
                  <div className="logo-area">
                    <a href="/">
                      <img
                        className="normal-logo"
                        src={props.isHome ? "/assets/images/logo-light.png" : "/assets/images/logo.png"}
                        alt="logo"
                      />
                      <img
                        className="sticky-logo"
                        src="/assets/images/logo.png"
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-cell">
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <nav className="rs-menu hidden-md">
                        <ul className="nav-menu">
                          <li>
                            <a href="/">Home</a>
                          </li>
                          <li>
                            <a href="/portfolios">Portfolio</a>
                          </li>
                          <li>
                            <a href="/contact">Contact</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="col-cell">
                  <div className="expand-btn-inner">
                    <ul>
                      <li className="nav-link">
                        <a
                          id="nav-expander"
                          className="nav-expander bar"
                          href="#"
                        >
                          <div className="bar">
                            <span className="dot1" />
                            <span className="dot2" />
                            <span className="dot3" />
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav className="right_menu_togle menu-wrap-off  hidden-md">
            <div className="close-btn">
              <a id="nav-close" className="nav-close">
                <div className="line">
                  <span className="line1" />
                  <span className="line2" />
                </div>
              </a>
            </div>
            <div className="rs-offcanvas-inner">
              <div className="canvas-logo">
                <a href="/">
                  <img src="/assets/images/logo.png" alt="logo" />
                </a>
              </div>
              <div className="offcanvas-text">
                <p>
                  We are your social media architects.
                </p>
              </div>
              <div className="canvas-contact">
                <div className="address-area">
                  <h2 className="widget-title">Get In Touch</h2>
                  <div className="address-list">
                    <div className="info-icon">
                      <i className="fi fi-rr-envelope-plus" />
                    </div>
                    <div className="info-content">
                      <h4 className="title">Email</h4>
                      <em>
                        <a href="mailto:info@snarvil.com">
                          info@snarvil.com
                        </a>
                      </em>
                    </div>
                  </div>
                  <div className="address-list">
                    <div className="info-icon">
                      <i className="fi fi-rr-phone-call" />
                    </div>
                    <div className="info-content">
                      <h4 className="title">Phone</h4>
                      <em>
                        <a href="tel:(+62)81547655988">(+62)81547655988</a>
                      </em>
                    </div>
                  </div>
                  <div className="address-list">
                    <div className="info-icon">
                      <i className="fi fi-rr-map-marker-home" />
                    </div>
                    <div className="info-content">
                      <h4 className="title">Location</h4>
                      <em>Jln Udayana, Buruan, Blahbatuh</em>
                      <em>Gianyar, Bali, Indonesia</em>
                    </div>
                  </div>
                </div>
                {/* <ul className="social">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-pinterest-p" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </nav>
          <nav
            className="right_menu_togle mobile-navbar-menu"
            id="mobile-navbar-menu"
          >
            <div className="close-btn">
              <a id="nav-close2" className="nav-close">
                <div className="line">
                  <span className="line1" />
                  <span className="line2" />
                </div>
              </a>
            </div>
            <ul className="nav-menu">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/portfolios">Portfolio</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
            <div className="canvas-contact">
              <div className="address-area">
                <div className="address-list">
                  <div className="info-icon">
                    <i className="fi fi-rr-map-marker-home" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Location</h4>
                    <em>Jln Udayana, Buruan, Blahbatuh</em>
                    <em>Gianyar, Bali, Indonesia</em>
                  </div>
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="fi fi-rr-envelope-plus" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Email</h4>
                    <em>
                      <a href="mailto:info@snarvil.com">
                        info@snarvil.com
                      </a>
                    </em>
                  </div>
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="fi fi-rr-phone-call" />
                  </div>
                  <div className="info-content">
                    <a href="tel:(+62)81547655988">(+62)81547655988</a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </Fragment>
  );
}

export default Header;
