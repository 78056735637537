import React, { Fragment, useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Portfolios from "../portfolio.json";

function Index() {
  const latestPortfolios = Portfolios.slice().reverse();

  const [count, setCount] = useState(9);

  const loadMore = () => {
    setCount(count + 9);
  };

  return (
    <Fragment>
      <div className="offwrap" />
      <div className="main-content">
        <Header isHome={true} />

        <div className="rs-banner banner-style2">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="content-wrap">
                  <h1 className="title">
                    Ignite Your
                    <br />
                    <span>Digital</span>
                  </h1>
                  <h1 className="title">Presence</h1>
                  <div className="description">
                    <p>
                      Elevate your Instagram presence affordably with Snarvil,
                      your dedicated social media management agency based in
                      Bali. Our expert team combines creativity and
                      cost-effectiveness to ensure your brand stands out online
                      without breaking the bank.
                    </p>
                  </div>
                  <div className="banner-btn">
                    <a className="readon orange" href="/contact">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="banner-img text-right md-text-center">
                  <img
                    src="/assets/images/banner/style2/image-1.png"
                    alt="Images"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="banner-animate">
            <div className="animation-style one">
              <img
                className="spine"
                src="/assets/images/banner/style2/shape1.png"
                alt="Images"
              />
            </div>
            <div className="animation-style two">
              <img
                className="left-right"
                src="/assets/images/banner/style2/shape2.png"
                alt="Images"
              />
            </div>
            <div className="animation-style three">
              <img
                className="rotated-style"
                src="/assets/images/banner/style2/shape3.png"
                alt="Images"
              />
            </div>
            <div className="animation-style four">
              <img
                className="left-right"
                src="/assets/images/banner/style2/shape4.png"
                alt="Images"
              />
            </div>
          </div>
        </div>
        <div className="rs-services services-style2 bg5 pt-143 pb-150 md-pt-75 md-pb-80">
          <div className="container custom">
            <div className="sec-title text-center mb-55 md-mb-35">
              <span className="sub-text">Our work process</span>
              <h2 className="title title3">
                Delivering first-class
                <br />
                websites
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 md-mb-30">
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img
                        src="/assets/images/services/style2/icons1.png"
                        alt="Services"
                      />
                    </div>
                    <div className="services-text">
                      <h2 className="title"> Strategy Development</h2>
                      <p className="services-txt">
                        Define clear objectives, target audience, and key
                        performance indicators (KPIs) to guide your social media
                        efforts. Craft a comprehensive strategy that aligns with
                        your brand identity, including content themes, posting
                        schedules, and engagement tactics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 md-mb-30">
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img
                        src="/assets/images/services/style2/icons2.png"
                        alt="Services"
                      />
                    </div>
                    <div className="services-text">
                      <h2 className="title">Content Creation and Curation</h2>
                      <p className="services-txt">
                        Develop compelling and relevant content that resonates
                        with your audience. This includes creating original
                        posts, graphics, and multimedia content. Additionally,
                        curate industry-relevant content to share, demonstrating
                        thought leadership and keeping your audience informed
                        and engaged.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 sm-mb-30">
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img
                        src="/assets/images/services/style2/icons3.png"
                        alt="Services"
                      />
                    </div>
                    <div className="services-text">
                      <h2 className="title">Scheduling and Posting</h2>
                      <p className="services-txt">
                        Implement a posting schedule based on optimal times for
                        your target audience. Utilize social media management
                        tools to schedule posts in advance, ensuring a
                        consistent and timely presence. This process helps
                        maintain an active and dynamic online profile while
                        allowing for strategic planning.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img
                        src="/assets/images/services/style2/icons4.png"
                        alt="Services"
                      />
                    </div>
                    <div className="services-text">
                      <h2 className="title">Monitoring and Analysis</h2>
                      <p className="services-txt">
                        Regularly monitor social media channels for comments,
                        messages, and mentions. Analyze performance metrics,
                        such as engagement rates, follower growth, and
                        conversion data, to assess the effectiveness of your
                        strategy. Use these insights to refine your approach,
                        optimize content, and adapt to evolving trends, ensuring
                        continuous improvement in your social media management
                        efforts.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ser-animate">
            <div className="animate-style one">
              <img src="/assets/images/services/style2/line.png" alt="Images" />
            </div>
            <div className="animate-style two">
              <img
                src="/assets/images/services/style2/line2.png"
                alt="Images"
              />
            </div>
            <div className="animate-style three">
              <img src="/assets/images/services/style2/line.png" alt="Images" />
            </div>
          </div>
        </div>
        <div className="rs-project project-style3 black-bg2 pt-145 pb-150 md-pt-75 md-pb-80">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-7 mb-50">
                <div className="sec-title">
                  <span className="sub-text white-color">portfolio</span>
                  <h2 className="title title3 white-color">
                    See more of our works
                  </h2>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="project-button pro-btn2 text-right md-left mt--40 md-mb-70">
                  <a
                    className="portfolio-single-column.html"
                    href="/portfolios"
                  >
                    See All Project
                    <i className="fa fi fi-rr-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              {latestPortfolios.slice(0, 2).map((portfolio) => (
                <div className="col-lg-6 project-item pr-56 md-pr-15 mb-30">
                  <div className="project-img">
                    <a href={"/portfolio/" + portfolio.slug}>
                      <img
                        src={
                          "/assets/images/portfolio/" +
                          portfolio.slug +
                          "/" +
                          portfolio.images[0]
                        }
                        alt="Images"
                      />
                    </a>
                    <div className="p-icon">
                      <a href={"/portfolio/" + portfolio.slug}>
                        View More
                        <i className="flaticon-next" />
                      </a>
                    </div>
                  </div>
                  <div className="project-content">
                    <span className="category">
                      <a href={"/portfolio/" + portfolio.slug}>
                        {portfolio.category}
                      </a>
                    </span>
                    <h3 className="title">
                      <a href={"/portfolio/" + portfolio.slug}>
                        {portfolio.title}
                      </a>
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="pro-animate">
            <div className="animation-style left-side">
              <img
                className="horizontal"
                src="/assets/images/project/style3/shape1.png"
                alt="Images"
              />
            </div>
          </div>
        </div>
        <div className="rs-about about-style1 pt-150 pb-150 md-pt-80 md-pb-70">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6 md-mb-50">
                <div className="about-img">
                  <img src="/assets/images/about/about-3.png" alt="Images" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="sec-title">
                  <span className="sub-text">About Snarvil</span>
                  <h2 className="title title3 pb-28">
                    Elevate your online presence with strategic social media
                    solutions
                  </h2>
                  <p className="desc pb-50 md-pb-35">
                    Discover cost-effective Instagram success with Snarvil, 
                    your dedicated social media management agency based
                    in Bali. Our talented team combines creativity and
                    affordability to elevate your brand's online presence
                    effortlessly.
                  </p>
                  <div className="btn-part">
                    <a className="readon orange started" href="/contact">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-animate-style">
            <div className="dot-animate">
              <img
                className="horizontal"
                src="/assets/images/about/style1/dot2.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="rs-cta cta-style1  blue-bg pt-145 pb-145 md-pt-105 md-pb-85">
          <div className="container">
            <div className="sec-title text-center">
              <h2 className="title title3 white-color pb-45 md-pb-30">
                Elevate your brand 
                <br />
                with us today!
              </h2>
              <div className="btn-part">
                <a className="readon orange contact" href="/contact">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <div className="cta-animation">
            <div className="animate-style one">
              <img
                className="scale"
                src="/assets/images/cta/shape1.png"
                alt=""
              />
            </div>
            <div className="animate-style two">
              <img
                className="scale"
                src="/assets/images/cta/shape2.png"
                alt=""
              />
            </div>
            <div className="animate-style three">
              <img
                className="horizontal"
                src="/assets/images/cta/shape3.png"
                alt=""
              />
            </div>
            <div className="animate-style four">
              <img
                className="spine"
                src="/assets/images/cta/shape4.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </Fragment>
  );
}

export default Index;
