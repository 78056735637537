import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Index from './Pages/Index';
import Portfolio from './Pages/Portfolio';
import Contact from './Pages/Contact';
import PortfolioSingle from './Pages/PortfolioSingle';

function App() {
  return (
    <main>
    <Router>
      <Routes>
        <Route path='/' element={<Index/>} />
        <Route path='/portfolios' element={<Portfolio/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path="/portfolio/:slug" element={<PortfolioSingle/>} />
      </Routes>
    </Router>
    </main>
  );
}

export default App;
