import React, { Fragment, useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Portfolios from "../portfolio.json";

function Portfolio() {
  const latestPortfolios = Portfolios.slice().reverse();

  const [count, setCount] = useState(9);

  const loadMore = () => {
    setCount(count + 9);
  };

  return (
    <Fragment>
      <div className="offwrap" />
      <div className="main-content">
        <Header isHome={false} />
        
        <div className="rs-project project-style1 project-modify12 pt-145 pb-150 md-pt-75 md-pb-80">
          <div className="container">
            <div className="sec-title mb-50 text-center">
              <span className="sub-text">portfolio</span>
              <h2 className="title">Our latest projects</h2>
            </div>
            <div className="row">
              {latestPortfolios.slice(0, count).map((portfolio) => (
              <div className="col-xl-4 col-md-6 mb-30">
                <div className="project-item">
                  <div className="project-img">
                    <a href={"/portfolio/" + portfolio.slug}>
                      <img
                        src={"/assets/images/portfolio/" + portfolio.slug + "/" + portfolio.images[0]}
                        alt="Images"
                      />
                    </a>
                  </div>
                  <div className="project-content">
                    <span className="category">
                      <a href={"/portfolio/" + portfolio.slug}>{portfolio.category}</a>
                    </span>
                    <h3 className="title">
                      <a href={"/portfolio/" + portfolio.slug}>{portfolio.title}</a>
                    </h3>
                  </div>
                </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </Fragment>
  );
}

export default Portfolio;
