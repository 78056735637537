import React, { Fragment, useState } from "react";

function Footer() {
  return (
    <Fragment>
      <footer
        id="rs-footer"
        className="rs-footer footer-main-home footer-modify2"
      >
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-lg-4 pr-20 md-pr-15 md-mb-20">
                <div className="footer-logo mb-85 md-mb-65">
                  <a href="/">
                    <img src="/assets/images/logo-light.png" alt="" />
                  </a>
                </div>
                <p className="widget-desc">
                  You will get weekly update on Email
                </p>
                <p>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    required=""
                  />
                  <input type="submit" defaultValue="Subscribe" />
                </p>
              </div>
              <div className="col-lg-4 md-mb-10 pl-80 md-pl-15">
                <h3 className="footer-title">Resources</h3>
                <ul className="site-map">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/portfolios">Portfolio</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <h3 className="footer-title">Information</h3>
                <ul className="address-widget">
                  <li>
                    <div className="desc">
                      Jln Udayana, Buruan, Blahbatuh
                      <br />
                      Gianyar, Bali, Indonesia
                    </div>
                  </li>
                  <li>
                    <div className="desc">
                      <a href="tel:(+62)81547655988">(+62)81547655988</a>
                      <br />
                    </div>
                  </li>
                  <li>
                    <div className="desc">
                      <a href="mailto:info@snarvil.com">
                        info@snarvil.com
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="bottom-border">
              <div className="row y-middle">
                <div className="col-lg-6">
                  <div className="copyright text-lg-start text-center">
                    <p>
                      © {(new Date().getFullYear())} Snarvil.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div id="scrollUp" className="orange-color">
        <i className="fa fa-angle-up" />
      </div>
    </Fragment>
  );
}

export default Footer;
